import './public-path';
import ready from '../src/ready';

ready(() => {
  const image = document.querySelector('img');
  if (image && image.id !== 'splash-img') {

    image.addEventListener('mouseenter', () => {
      image.src = '/eunomia/oops.gif';
    });

    image.addEventListener('mouseleave', () => {
      image.src = '/eunomia/oops.png';
    });
  }
});
